<template>
  <v-btn-toggle
    :value="value"
    @change="handleChange"
    dense
    :mandatory="!includeRoomCharge"
    color="primary"
    background-color="transparent"
    borderless
    class="flex-row-wrap">
    <v-btn v-for="(paymentMethod, i) in filteredPaymentMethods" :key="i" :value="paymentMethod.id" :ref="`paymentMethodBtn${paymentMethod.id || ''}`" :disabled="disabled">
      {{paymentMethod.name}}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { roomChargePaymentMethod } from '@/config'
import { mapGetters } from 'vuex'

export default {
  // EMITTED ACTIONS: change
  props: {
    value: {
      type: String,
      required: false
    },
    includeRoomCharge: Boolean,
    disabled: Boolean,
    excludedPaymentMethodIds: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'configuration/paymentMethods'
    }),
    filteredPaymentMethods () {
      const list = this.paymentMethods.filter((p) => !this.excludedPaymentMethodIds || !this.excludedPaymentMethodIds.includes(p.id))
      if (this.includeRoomCharge) {
        list.push(roomChargePaymentMethod)
      }
      return list
    }
  },
  methods: {
    handleChange (value) {
      const v = typeof value === 'number' ? null : value
      this.$emit('change', v)
      if (this.$refs.paymentMethodBtn) {
        this.$refs.paymentMethodBtn[0].$el.classList.value = v 
          ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default'
          : 'v-btn v-item--active v-btn--active v-btn--is-elevated v-btn--has-bg theme--light v-size--default'
      }
    }
  }
}
</script>
